/**
 * ACTION TYPES
 */

const OPENED_MODAL = 'OPENED_MODAL';
const CLOSED_MODAL = 'CLOSED_MODAL';

/**
 * INITIAL STATE
 */

const initialState = null;

/**
 * ACTION CREATORS
 */

export const openedModal = id => ({ type: OPENED_MODAL, id });
export const closedModal = () => ({ type: CLOSED_MODAL });



/**
 * REDUCER
 */

export default function (state = initialState, action) {
  switch (action.type) {
    case OPENED_MODAL:
      return action.id;
    case CLOSED_MODAL:
      return initialState;
    default:
      return state;
  }
}
