export const addPulseName = locationName => {
  const ring = document.getElementById(`ringing${locationName}`)
  ring.style.border = `3px solid rgba(189, 25, 25, 1)`
  ring.style.transition = `border 2s linear`
  const name = document.getElementById(`location${locationName}`)
  name.style.color = `rgba(255, 255, 255, 1)`
  name.style.zIndex = '130'
}

export const removePulseName = locationName => {
  const ring = document.getElementById(`ringing${locationName.location}`)
  ring.style.border = `3px solid rgba(189, 25, 25, 0)`
  ring.style.transition = 'none'
  const name = document.getElementById(`location${locationName.location}`)
  name.style.color = `rgba(255, 255, 255, 0)`
}

export const makeRed = locationName => {
  const circle = document.getElementById(`circle${locationName}`)
  circle.style.backgroundColor = `rgba(189, 25, 25, 1)`
  circle.style.transition = 'background-color 0.6s linear'
}

export const makeGray = locationName => {
  const circle = document.getElementById(`circle${locationName}`)
  circle.style.backgroundColor = `gray`
}

export const makeInvisible = locationName => {
  const circle = document.getElementById(`circle${locationName}`)
  circle.style.backgroundColor = `rgba(189, 25, 25, 0)`
}
