import {
  removePulseName,
  makeGray,
  addPulseName,
  makeRed,
  makeInvisible
} from './pin'

export const handleClose = (video, upNext, videoHash) => {
  const videos = videoHash[video.location]
  const isInvisible = videos.reduce((a, b) => {
    if (!b.completed) {
      return true
    } else {
      return a
    }
  }, false)
  if (!isInvisible) {
    removePulseName(video)
    makeGray(video.location)
    if (upNext) {
      addPulseName(upNext)
      makeRed(upNext)
    }
  } else {
    if (video.completed) {
      removePulseName(video)
      makeGray(video.location)
    }
    addPulseName(upNext)
    makeRed(upNext)
  }
}
