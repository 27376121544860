import React, {Component} from 'react'

const newCityPin = props => {
  return [
    `<div
      class="ringring new-city"
      id="new-ring"
      style="
        border: 3px solid transparent;
        top: calc(${props.newCityCoord.positionY}vh - 12.5px);
        left: calc(${props.newCityCoord.positionX}vw - 12.5px);
      "
    />`,
    `<div
      class="circle new-city"
      id="new-circle"
      style="
        background-color: transparent;
        top: calc(${props.newCityCoord.positionY}vh - 7.5px);
        left: calc(${props.newCityCoord.positionX}vw - 7.5px);
      "
    />`,
    `<h3
      class="location new-city"
      id="new-label"
      style="
        font-family: Helvetica, sans serif;
        color: transparent;
        top: calc(${props.newCityCoord.positionY}vh - 17.5px);
        left: calc(${props.newCityCoord.positionX}vw - 3.5px);
        position: absolute
      "
    >
      ${props.cityName}
    </h3>`
  ]
}

export default newCityPin
