/**
 * ACTION TYPES
 */
const SET_UP_NEXT = 'SET_UP_NEXT';

/**
 * INITIAL STATE
 */
const initialState = null;

/**
 * ACTION CREATORS
 */
export const setUpNext = (videoId) => ({type: SET_UP_NEXT, videoId});

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case SET_UP_NEXT:
      return action.videoId;
    default:
      return state;
  }
}
