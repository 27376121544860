import React, {Component} from 'react'
import {connect} from 'react-redux'
import VideoSelectionResizer from './video-selection-resizer'
import moment from 'moment';

class VideoPlaylist extends Component {
  render() {
    const { totalTime, remainingTime } = this.props;
    return (
      <div id="playlist-container">
        <div>
          <div id="playlist-header">
            <h3>Playlist of videos</h3>
            <div className="runningTime">
              <h4>{`Total running time: ${totalTime || ''}`}</h4>
              <h4>{`Remaining time: ${remainingTime || ''}`}</h4>
            </div>
          </div>
          <VideoSelectionResizer addPage={this.props.addPage} />
          {!this.props.addPage &&
            (this.props.allCompleted ? (
              <div id="completion">
                <h1 id="completion-title">Nice Job!</h1>
                <h3>
                  You have leveled up to the next part of <em>Hacking 101.</em>
                </h3>
              </div>
            ) : (
              <div>
                <p>
                  After you watch each video, you'll earn a green checkmark.
                </p>
                <p>
                  When you've watched them all, you'll have a deep understanding
                </p>
              </div>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({user}) => ({
  totalTime: user.totalTime,
  remainingTime: user.remainingTime
});

export default connect(mapStateToProps)(VideoPlaylist);
