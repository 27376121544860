import React, {Component} from 'react'
import {connect} from 'react-redux'
import VideoSelection from './video-selection'
import {Resizable, ResizableBox} from 'react-resizable'

const MIN_WIDTH = window.innerWidth * 0.05
const MIN_HEIGHT = window.innerHeight * 0.05
const INITIAL_WIDTH = window.innerWidth * 0.23
const INITIAL_HEIGHT = window.innerHeight * 0.23
const MAX_WIDTH = window.innerWidth * 0.95
const MAX_HEIGHT = window.innerHeight * 0.4

class VideoSelectionResizer extends Component {
  constructor() {
    super()
    this.state = {
      width: INITIAL_WIDTH, // to mimic 23vw rule in stylesheet
      height: 0, // will be overwritten by accordion once size of contents known
      touched: false
    }
    this.setParentHeight = this.setParentHeight.bind(this)
  }

  setParentHeight(height) {
    if (!this.state.touched) {
      // initial load, not enough content to fill initial height so use content
      this.setState({height: height < INITIAL_HEIGHT ? height : INITIAL_HEIGHT})
    } else {
      // the user has already decided to drag this component so it's OK if text
      // below is off-screen
      this.setState({height: height < MAX_HEIGHT ? height : MAX_HEIGHT})
    }
  }

  onResize = (event, metadata) => {
    const {width, height} = metadata.size
    this.setState({width, height, touched: true})
  }

  render() {
    return (
      <Resizable
        className="box"
        width={this.state.width}
        height={this.state.height}
        minConstraints={[MIN_WIDTH, MIN_HEIGHT]}
        maxConstraints={[MAX_WIDTH, MAX_HEIGHT]}
        onResize={this.onResize}
      >
        <div
          style={{
            width: this.state.width + 'px',
            height: this.state.height ? this.state.height + 'px' : '100%'
          }}
        >
          <VideoSelection
            addPage={this.props.addPage}
            setParentHeight={this.setParentHeight} />
        </div>
      </Resizable>
    )
  }
}

export default VideoSelectionResizer
