import React, { Component } from 'react';
import { connect } from 'react-redux';
import {Popup, Icon} from 'semantic-ui-react';
import _ from 'lodash';

import { animation } from './animationFuncs';
import { createVideoProgress, updateVideoProgress } from '../store/videos';

class VideoPopup extends Component {



  render() {
    const {video, createProgress, updateProgress, upNext, videoHash, idx} = this.props;

    return (
      <Popup
        position="right center"
        hideOnScroll
        key={idx}
        inverted
        content={video.label}
        trigger={
          <div
            id={`${video.id}`}
            className="video-list-item"
            key={video.id}
            onClick={() => {
              animation(
                video,
                createProgress,
                updateProgress,
                upNext,
                null,
                videoHash
              );
            }}
          >
            {video.completed ? (
              <Icon name="check" color="green" />
            ) : video.started ? (
              <Icon name="map marker alternate" color="red" />
            ) : (
              <Icon name="map marker alternate" color="blue" />
            )}
            {video.label}
          </div>
        }
      />
    );
      }
};

const mapStateToProps = ({ videos, upNext }, {  }) => ({
  videoHash: _.groupBy(videos, 'location'),
  upNext: _.find(videos, { id: upNext })
});

const mapDispatchToProps = dispatch => ({
  createProgress: id => dispatch(createVideoProgress(id)),
  updateProgress: (id, lastStoppedAt, completed, selected) =>
    dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),

});

export default connect(mapStateToProps, mapDispatchToProps)(VideoPopup);
