import axios from 'axios';
import {getVideoById, getVideosAfterUpdate, getVideosWithUpNext} from '../utils';
import {getVideoTotalTimes} from './user';
import {getSections} from './sections';

/**
 * ACTION TYPES
 */
const GET_VIDEOS = 'GET_VIDEOS';
const CREATE_VIDEO_PROGRESS = 'CREATE_VIDEO_PROGRESS';
const UPDATE_VIDEO_PROGRESS = 'UPDATE_VIDEO_PROGRESS';
const ADD_VIDEO = 'ADD_VIDEO';
const UPDATE_VIDEO = 'UPDATE_VIDEO';
const UPDATE_ORDER = 'UPDATE_ORDER';
const DELETE_VIDEO = 'DELETE_VIDEO';
/**
 * INITIAL STATE
 */
const initialState = [];

/**
 * ACTION CREATORS
 */

const deletedVideo = videoId => ({
  type: DELETE_VIDEO,
  videoId
});

export const gotVideos = videos => ({
  type: GET_VIDEOS,
  videos
});

export const acCreateVideoProgress = progress => ({
  type: CREATE_VIDEO_PROGRESS,
  progress
});

export const acUpdateVideoProgress = progress => ({
  type: UPDATE_VIDEO_PROGRESS,
  progress
});

export const videoCreated = video => {
  return {
    type: ADD_VIDEO,
    video
  };
};

export const videoUpdated = video => ({
  type: UPDATE_VIDEO,
  video
});

export const updateOrder = video => ({
  type: UPDATE_ORDER,
  video
});
/**
 * THUNK CREATORS
 */

export const getVideos = () => async dispatch => {
  try {
    const res = await axios.get(`/api/videos/`);
    dispatch(gotVideos(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const deleteVideo = videoId => async dispatch => {
  try {
    const res = await axios.delete(`/api/videos/${videoId}`);
    // dispatch(deletedVideo(res.data))
    dispatch(getSections());
    dispatch(getVideos());
  } catch (error) {
    console.error(error);
  }
};

export const createVideoProgress = videoId => async dispatch => {
  try {
    const res = await axios.post(`/api/progress/`, {videoId});
    dispatch(acCreateVideoProgress(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const createVideo = video => async dispatch => {
  try {
    const res = await axios.post('/api/videos', video);
    await dispatch(videoCreated(res.data));
    await dispatch(getSections());
    await dispatch(getVideos());
  } catch (error) {
    console.error(error);
  }
};

export const updateVideo = video => async dispatch => {
  try {
    const res = await axios.put(`/api/videos/${video.id}`, video);
    await dispatch(videoUpdated(res.data));
    await dispatch(getSections());
    await dispatch(getVideos());
  } catch (error) {
    console.error(error);
  }
};

export const updateVideoProgress = (
  videoId,
  lastStoppedAt,
  completed,
  selected
) => async dispatch => {
  try {
    const res = await axios.put(`/api/progress/`, {
      videoId,
      lastStoppedAt,
      completed,
      selected
    });
    await dispatch(acUpdateVideoProgress(res.data));
    dispatch(getVideoTotalTimes());
  } catch (error) {
    console.error(error);
  }
};

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GET_VIDEOS:
      return action.videos;
    case CREATE_VIDEO_PROGRESS:
    case UPDATE_VIDEO_PROGRESS: {
      const selectedVideo = getVideoById(state, action.progress.videoId);
      const updatedVideo = {...selectedVideo, ...action.progress, started: true};
      const videos = getVideosAfterUpdate(state, updatedVideo);
      return getVideosWithUpNext(videos);
    }
    case ADD_VIDEO:
      return [...state, action.video];
    case UPDATE_VIDEO:
      return getVideosAfterUpdate(state, action.video);
    // // case DELETE_VIDEO: {
    // //   const nextState = [...state]
    // //   return
    // }
    default:
      return state;
  }
}
