import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Popup, Icon} from 'semantic-ui-react';
import {animation} from '../animationFuncs';
import { createVideoProgress, updateVideoProgress } from '../../store/videos';


class ActivePin extends Component {
  render () {
    const {
      location,
      videos,
      upNext,
      videoHash,
      createProgress,
      updateProgress
    } = this.props;

    const triggerDot = (<div className="circle"
      id={`circle${location.name}`}
      style={{
        top: `calc(${location.positionY}vh - 7.5px)`,
        left: `calc(${location.positionX}vw - 7.5px)`,
        backgroundColor: 'rgba(189, 25, 25, 1)',
        pointerEvents: 'auto',
        zIndex: '100'
      }} />);

    return (
      <React.Fragment>
        <div className="ringring"
          id={`ringing${location.name}`}
          style={{
            border: '3px solid rgba(189, 25, 25, 1)',
            top: `calc(${location.positionY}vh - 12.5px)`,
            left: `calc(${location.positionX}vw - 12.5px)`
          }} />
        <Popup header={location.name}
          inverted
          hoverable
          trigger={ triggerDot }
          content={videos.map(video => {
            return (
              <div id={ video.id }
                className="video-list-item"
                key={ video.id }
                onClick={() =>
                  animation(
                    video,
                    createProgress,
                    updateProgress,
                    upNext,
                    null,
                    videoHash
                  )
                } >
                {video.completed ? (
                  <Icon name="check" color="green" />
                ) : video.started ? (
                  <Icon name="map marker alternate" color="red" />
                ) : (
                  <Icon name="map marker alternate" color="blue" />
                )}
                {video.label}
              </div>
            );
          })}
        />

        <h3 className="location"
          id={`location${location.name}`}
          style={{
            fontFamily: 'Helvetica, sans serif',
            color: `rgba(255, 255, 255, 1)`,
            top: `calc(${location.positionY}vh - 17.5px)`,
            left: `calc(${location.positionX}vw - 3.5px)`,
            position: 'absolute',
            pointerEvents: 'none',
            zIndex: '200'
          }} >
          {location.name}
        </h3>
      </React.Fragment>
    );
  }
}


const mapStateToProps = ({ videos, upNext, locations }, { locationId }) => ({
  videos: _.filter(videos, { locationId, started: true }),
  upNext: _.find(videos, { id: upNext }),
  locations,
  location: _.find(locations, { id: locationId }),
  videoHash: _(videos).filter('started').groupBy('location').value()
});

const mapDispatchToProps = dispatch => ({
  updateProgress: (id, lastStoppedAt, completed, selected) =>
  dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),
  createProgress: id => dispatch(createVideoProgress(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivePin);
