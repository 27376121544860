export const target = (x, y) => {
  const topLeft = document.getElementById('top-left').style
  topLeft.transition = `top 0.7s ease-out, left 0.7s ease-out, width 0.7s ease-out,height 0.7s ease-out, transform 0.7s ease-out`
  topLeft.top = `calc(${y}vh - 50px)`
  topLeft.left = `calc(${x}vw - 49px)`
  topLeft.transform = `scale(0.2)`
  topLeft.zIndex = 125

  const topRight = document.getElementById('top-right').style
  topRight.transition = `top 0.7s ease-out, left 0.7s ease-out, width 0.7s ease-out,height 0.7s ease-out, transform 0.7s ease-out`
  topRight.top = `calc(${y}vh - 50px)`
  topRight.left = `calc(${x}vw - 19px)`
  topRight.transform = `scale(0.2)`
  topRight.zIndex = 125

  const bottomRight = document.getElementById('bottom-right').style
  bottomRight.transition = `top 0.7s ease-out, left 0.7s ease-out, width 0.7s ease-out,height 0.7s ease-out, transform 0.7s ease-out`
  bottomRight.top = `calc(${y}vh - 20px)`
  bottomRight.left = `calc(${x}vw - 19px)`
  bottomRight.transform = `scale(0.2)`
  bottomRight.zIndex = 125

  const bottomLeft = document.getElementById('bottom-left').style
  bottomLeft.transition = `top 0.7s ease-out, left 0.7s ease-out, width 0.7s ease-out,height 0.7s ease-out, transform 0.7s ease-out`
  bottomLeft.top = `calc(${y}vh - 20px)`
  bottomLeft.left = `calc(${x}vw - 49px)`
  bottomLeft.transform = `scale(0.2)`
  bottomLeft.zIndex = 125
}

export const fade = () => {
  const topLeft = document.getElementById('top-left').style
  topLeft.transition = `border-left 1.5s ease-in, border-top 1.5s ease-in`
  topLeft.borderLeft = `10px solid rgba(255, 222, 0, 0)`
  topLeft.borderTop = `10px solid rgba(255, 222, 0, 0)`

  const topRight = document.getElementById('top-right').style
  topRight.transition = `border-right 1.5s ease-in, border-top 1.5s ease-in`
  topRight.borderRight = `10px solid rgba(255, 222, 0, 0)`
  topRight.borderTop = `10px solid rgba(255, 222, 0, 0)`

  const bottomRight = document.getElementById('bottom-right').style
  bottomRight.transition = `border-right 1.5s ease-in, border-bottom 1.5s ease-in`
  bottomRight.borderRight = `10px solid rgba(255, 222, 0, 0)`
  bottomRight.borderBottom = `10px solid rgba(255, 222, 0, 0)`

  const bottomLeft = document.getElementById('bottom-left').style
  bottomLeft.transition = `border-left 1.5s ease-in, border-bottom 1.5s ease-in`
  bottomLeft.borderLeft = `10px solid rgba(255, 222, 0, 0)`
  bottomLeft.borderBottom = `10px solid rgba(255, 222, 0, 0)`
}

// I don't know why this spread operator doesn't work

// export const fade = () => {
//   const topLeft = document.getElementById('top-left')
//   topLeft.style = {
//     ...topLeft.style,
//     transition: `border-left 2s ease-in, border-top 2s ease-in`,
//     borderLeft: `10px solid rgba(255, 222, 0, 0)`,
//     borderTop: `10px solid rgba(255, 222, 0, 0)`
//   }

//   const topRight = document.getElementById('top-right')
//   topRight.style = {
//     ...topRight.style,
//     transition: `border-right 2s ease-in, border-top 2s ease-in`,
//     borderRight: `10px solid rgba(255, 222, 0, 0)`,
//     borderTop: `10px solid rgba(255, 222, 0, 0)`
//   }

//   const bottomRight = document.getElementById('bottom-right')
//   bottomRight.style = {
//     ...bottomRight.style,
//     transition: `border-right 2s ease-in, border-bottom 2s ease-in`,
//     borderRight: `10px solid rgba(255, 222, 0, 0)`,
//     borderBottom: `10px solid rgba(255, 222, 0, 0)`
//   }

//   const bottomLeft = document.getElementById('bottom-left')
//   bottomLeft.style = {
//     ...bottomLeft.style,
//     transition: `border-left 2s ease-in, border-bottom 2s ease-in`,
//     borderLeft: `10px solid rgba(255, 222, 0, 0)`,
//     borderBottom: `10px solid rgba(255, 222, 0, 0)`
//   }
// }

export const reset = () => {
  const topLeft = document.getElementById('top-left')
  topLeft.style = {
    ...topLeft.style,
    transition: null,
    top: `0vh`,
    left: `0vw`,
    transform: `scale(1)`,
    zIndex: -1,
    borderLeft: `10px solid rgba(255, 222, 0, 1)`,
    borderTop: `10px solid rgba(255, 222, 0, 1)`
  }

  const topRight = document.getElementById('top-right')
  topRight.style = {
    ...topRight.style,
    transition: null,
    top: `0vh`,
    left: `100vw`,
    transform: `scale(1)`,
    zIndex: -1,
    borderRight: `10px solid rgba(255, 222, 0, 1)`,
    borderTop: `10px solid rgba(255, 222, 0, 1)`
  }

  const bottomRight = document.getElementById('bottom-right')
  bottomRight.style = {
    ...bottomRight.style,
    transition: null,
    top: `100vh`,
    left: `100vw`,
    transform: `scale(1)`,
    zIndex: -1,
    borderRight: `10px solid rgba(255, 222, 0, 1)`,
    borderBottom: `10px solid rgba(255, 222, 0, 1)`
  }

  const bottomLeft = document.getElementById('bottom-left')
  bottomLeft.style = {
    ...bottomLeft.style,
    transition: null,
    top: `100vh`,
    left: `0vw`,
    transform: `scale(1)`,
    zIndex: -1,
    borderLeft: `10px solid rgba(255, 222, 0, 1)`,
    borderBottom: `10px solid rgba(255, 222, 0, 1)`
  }
}
