import axios from 'axios';
import _ from 'lodash';
import {UPDATE_ORDER} from './videos';

/**
 * ACTION TYPES
 */
const GOT_SECTIONS = 'GOT_SECTIONS';
const ADD_SECTION = 'ADD_SECTION';
const UPDATE_SECTION = 'UPDATE_SECTION';
/**
 * INITIAL STATE
 */
const initialState = [];

/**
 * ACTION CREATORS
 */
export const gotSections = sections => ({type: GOT_SECTIONS, sections});
export const addSection = section => ({type: ADD_SECTION, section});
export const sectionUpdated = section => ({type: UPDATE_SECTION, section});
/**
 * THUNK CREATORS
 */

export const getSections = () => async dispatch => {
  try {
    const res = await axios.get(`/api/sections/`);
    dispatch(gotSections(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const addNewSection = (label, order) => async dispatch => {
  try {
    const res = await axios.post('/api/sections', {label, order});
    dispatch(addSection(res.data));
  } catch (error) {
    console.error(error);
  }
};

export const updateSection = data => async dispatch => {
  try {
    const res = await axios.put(`/api/sections/${data.id}`, data);
    dispatch(sectionUpdated(res.data));
    dispatch(getSections());
  } catch(error) {
    console.error(error);
  }
};

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case GOT_SECTIONS:
      return action.sections;
    case ADD_SECTION:
      return [...state, action.section];
    case UPDATE_SECTION:
      return _.reject(state, { id: action.section.id }).concat(action.section);
    case UPDATE_ORDER:
      return;

    default:
      return state;
  }
}
