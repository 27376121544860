/* The purpose of the below is to take the result of the GET route for /api/videos, which results in a complicated object with many associations, to a flat object with only the details we need before sending to the front-end. */
function flattenVideoQueryObj(video, upNextId) {
  return {
    id: video.id,
    urlCode: video.urlCode,
    start: video.start,
    end: video.end,
    label: video.label,
    order: video.order,
    sectionId: video.section.id,
    upNext: video.id === upNextId,
    started: progressExists(video),
    completed: getProgressProp(video, 'completed', false),
    selected: getProgressProp(video, 'selected', false),
    lastStoppedAt: getProgressProp(video, 'lastStoppedAt'),
    updatedAt: getProgressProp(video, 'updatedAt'),
    location: getLocationProp(video, 'name'),
    positionX: getLocationProp(video, 'positionX'),
    positionY: getLocationProp(video, 'positionY'),
    length: video.length,
    locationId: video.locationId
  }
}

function getUpNext(videos) {
  // OR conditions exist b/c this function is used both for backend non-flattened
  // objects as well as front-end flattened objects.
  const unfinishedVids = videos.filter(
    video =>
      (video.started === true || progressExists(video)) &&
      (video.completed === false ||
        getProgressProp(video, 'completed') === false)
  )
  const maxIncompleteDate = getMostRecentUpdateTime(unfinishedVids)
  return getVideoByUpdatedDate(unfinishedVids, maxIncompleteDate)
}

function progressExists(video) {
  return !!(video.users && video.users[0])
}

function getProgressProp(video, prop, ifAbsent = null) {
  return progressExists(video) ? video.users[0].progress[prop] : ifAbsent
}

function getLocationProp(video, prop, ifAbsent) {
  return video.location ? video.location[prop] : ifAbsent
}

function getLastUpdateInSeconds(video) {
  const updatedAt = progressExists(video)
    ? getProgressProp(video, 'updatedAt') // if this is straight from the db
    : video.updatedAt // if this is already a flattened video object
  return new Date(updatedAt).getTime()
}

function getMostRecentUpdateTime(videos) {
  return videos.length > 0
    ? Math.max(...videos.map(video => getLastUpdateInSeconds(video)))
    : null
}

function getVideoByUpdatedDate(videos, updatedDate) {
  return updatedDate
    ? videos.filter(video => getLastUpdateInSeconds(video) === updatedDate)[0]
    : null
}

function convertSecsToHoursMinutes(seconds) {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds / 60) % 60)
  return [hours, minutes]
}

module.exports = {
  flattenVideoQueryObj,
  getUpNext,
  convertSecsToHoursMinutes
}

/*
BEFORE FLATTENING (OMITTING OTHER FIELDS FOR BREVITY):
{
  "id": 1,
  "link": "https://www.youtube.com/embed/xq3db2DwmN4?start=105&end=233",
  "label": "Cybersecurity as a national threat",
  "section": {
      "id": 1,
  },
  "users": [
      {
          "progress": {
              "lastStoppedAt": 0,
              "updatedAt": "2018-08-07 14:40:49.757-04",
              "completed": false
          }
      }
  ],
  "city": {
      "name": "Washington, DC",
      "positionX": "42.30",
      "positionY": "28.30"
  }
}

AFTER:
{
  "videoId": 1,
  "link": "https://www.youtube.com/embed/xq3db2DwmN4?start=105&end=233",
  "label": "Cybersecurity as a national threat",
  "sectionId": 1,
  "lastStoppedAt": 0,
  "updatedAt": "2018-08-07 14:40:49.757-04"
  "completed": false,
  "city": "Washington, DC",
  "positionX": "42.30",
  "positionY": "28.30"
}
*/
