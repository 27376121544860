import React from 'react'

const Pin = props => {
  return (
    <React.Fragment>
      <div
        className="ringring"
        id={`ringing${props.id}`}
        style={{
          backgroundColor: '3px solid rgba(189, 25, 25, 0)',
          top: `calc(${props.y}vh - 12.5px)`,
          left: `calc(${props.x}vw - 12.5px)`
        }}
      />
      <div
        className="circle"
        id={`circle${props.id}`}
        style={{
          backgroundColor: 'rgba(189, 25, 25, 0)',
          top: `calc(${props.y}vh - 7.5px)`,
          left: `calc(${props.x}vw - 7.5px)`
        }}
      />
      <h3
        className="location"
        id={`location${props.id}`}
        style={{
          fontFamily: 'Helvetica, sans serif',
          top: `calc(${props.y}vh - 17.5px)`,
          left: `calc(${props.x}vw - 3.5px)`,
          position: 'absolute',
          zIndex: '-1'
        }}
      >
        {props.id}
      </h3>
    </React.Fragment>
  )
}

export default Pin
