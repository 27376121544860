import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {Popup, Icon} from 'semantic-ui-react';
import {animation} from '../animationFuncs';
import { createVideoProgress, updateVideoProgress } from '../../store/videos';


class GreyPin extends Component {
  render() {
    const props = this.props;
    const { videos, upNext, createProgress, updateProgress } = this.props;

    return (
      <React.Fragment>
        <div className="ringring"
          id={`ringing${props.id}`}
          style={{
            top: `calc(${props.y}vh - 12.5px)`,
            left: `calc(${props.x}vw - 12.5px)`
          }} />

        <Popup header={props.id}
          inverted
          hoverable
          trigger={
            <div
              className="circle"
              id={`circle${props.id}`}
              style={{
                backgroundColor: 'grey',
                top: `calc(${props.y}vh - 7.5px)`,
                left: `calc(${props.x}vw - 7.5px)`
              }}
            />
          }
          content={
            _.map(videos, video => {
              if (video.completed) {
                return (
                  <div
                    id={`${video.id}`}
                    className="video-list-item"
                    key={video.id}
                    onClick={() =>
                      animation(
                        video,
                        createProgress,
                        updateProgress,
                        upNext,
                        null,
                        props.videoHash
                      )
                    }
                  >
                    {video.completed ? (
                      <Icon name="check" color="green" />
                    ) : video.started ? (
                      <Icon name="map marker alternate" color="red" />
                    ) : (
                      <Icon name="map marker alternate" color="blue" />
                    )}
                    {video.label}
                  </div>
                );
              }
            })
          }
        />
        <h3 className="location"
          id={`location${props.id}`}
          style={{
            fontFamily: 'Helvetica, sans serif',
            top: `calc(${props.y}vh - 17.5px)`,
            left: `calc(${props.x}vw - 3.5px)`,
            zIndex: '-1'
          }} >
          {props.id}
        </h3>
      </React.Fragment>
    );
  };
}

const mapStateToProps = ({ videos, upNext }, { location }) => ({
  videos: _.filter(videos, { location, started: true }),
  upNext: _.find(videos, { id: upNext })
});

const mapDispatchToProps = dispatch => ({
  updateProgress: (id, lastStoppedAt, completed, selected) =>
  dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),
  createProgress: id => dispatch(createVideoProgress(id)),
});

export default connect(mapStateToProps, mapDispatchToProps)(GreyPin);
