import _ from 'lodash';
import {target, fade, reset} from './corners';
import {
  makeRed,
  addPulseName,
  removePulseName,
  makeGray,
  makeInvisible
} from './pin';

export const animation = (
  video,
  createProgress,
  updateProgress,
  previousPulse,
  isAutoplay,
  videoHash
) => {
  const lastLocationVideos = previousPulse && videoHash[previousPulse.location];
  const hasCompletedVideo = _.some(lastLocationVideos, { completed: true });

  const self = document.getElementById('playlist-container');
  if (self.dataset.active === 'false' || !self.dataset.active) {
    self.dataset.active = true;
    if (isAutoplay) {
      if (hasCompletedVideo) makeGray(previousPulse.location);
      else makeInvisible(previousPulse.location);
      removePulseName(previousPulse);
    } else if (previousPulse && previousPulse.location !== video.location) {
      removePulseName(previousPulse);
      if (hasCompletedVideo) {
        makeGray(previousPulse.location);
      } else {
        makeInvisible(previousPulse.location);
      }
    }
    target(video.positionX, video.positionY);
    let startVideoDelay = 2200;
    if (
      !previousPulse ||
      video.location !== previousPulse.location ||
      isAutoplay
    ) {
      startVideoDelay = 4200;
      setTimeout(() => {
        fade();
        if (!video.complete) {
          makeRed(video.location);
          addPulseName(video.location);
        }
      }, 2200);
    }
    setTimeout(() => {
      reset();
      self.dataset.active = false;
      if (!video.started) {
        createProgress(video.id);
      } else {
        updateProgress(video.id, video.lastStoppedAt, video.completed, true);
      }
    }, startVideoDelay);
  }
};
