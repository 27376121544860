/**
 * ACTION TYPES
 */

const OPENED_NEW_CITY_MODAL = 'OPENED_NEW_CITY_MODAL';
const CONFIRM_NEW_CITY = 'CONFIRM_NEW_CITY';
const CLOSED_NEW_CITY_MODAL = 'CLOSED_NEW_CITY_MODAL';

/**
 * INITIAL STATE
 */

const initialState = 0;

/**
 * ACTION CREATORS
 */

export const openedNewCityModal = () => ({ type: OPENED_NEW_CITY_MODAL });
export const confirmNewCity = () => ({ type: CONFIRM_NEW_CITY });
export const closedNewCityModal = () => ({ type: CLOSED_NEW_CITY_MODAL });



/**
 * REDUCER
 */

export default function (state = initialState, action) {
  switch (action.type) {
    case CONFIRM_NEW_CITY:
      return 2;
    case OPENED_NEW_CITY_MODAL:
      return 1;
    case CLOSED_NEW_CITY_MODAL:
      return 0;
    default:
      return state;
  }
}
