import React, { Component } from 'react';
import YouTube from 'react-youtube';
import { connect } from 'react-redux';
import _ from 'lodash';
import { updateVideoProgress, createVideoProgress } from '../store/videos';
import { Icon } from 'semantic-ui-react';
import { animation } from './animationFuncs';
import { handleClose } from './animationFuncs/closePlayer';
import { updateLastStoppedAt, getNextAutoplayVideo } from '../utils';

class YouTubePlayer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      height: '390',
      width: '640',
      playerVars: {
        autoplay: 1,
        start: props.video.lastStoppedAt || props.video.start,
        end: props.video.end,
        iv_load_policy: 3
      }
    };
    this.onCompleteVideo = this.onCompleteVideo.bind(this);
  }

  onCompleteVideo(currentVideoId) {
    const {
      createProgress,
      updateProgress,
      video,
      videos,
      autoplay,
      nextVideo
    } = this.props;

    updateProgress(currentVideoId, video.start, true, false);
    if (autoplay && nextVideo) {
      const currentVideo = videos.filter(vid => {
        return currentVideoId === vid.id;
      })[0];
      animation(
        nextVideo,
        createProgress,
        updateProgress,
        currentVideo,
        true,
        this.props.videoHash
      );
    } else if (autoplay && !nextVideo) {
      handleClose(this.props.video, null, this.props.videoHash);
    }
  }

  onPauseVideo = event => {
    let { video } = this.props;
    this.props.updateProgress(
      video.id,
      updateLastStoppedAt(event.target.getCurrentTime(), video),
      video.completed,
      true
    );
  }

  onExitVideo = event => {
    let { video, upNext = {}, videoHash } = this.props;
    let exitButton = document.getElementById("close-video");
    const update = this.props.updateProgress;
    exitButton.addEventListener("click", function() {
      handleClose(video, upNext.location, videoHash);
      return update(
        video.id,
        updateLastStoppedAt(event.target.getCurrentTime(), video),
        video.completed,
        false
      );
    });
  }

  render() {
    let { video } = this.props;
    return (
      <React.Fragment>
        <div id="selected-video-container">
          <YouTube
            id="selected-video"
            videoHash={this.props.videoHash}
            videoId={video.urlCode}
            opts={this.state}
            onEnd={() => this.onCompleteVideo(video.id)}
            onPause={event => this.onPauseVideo(event)}
            onReady={event => this.onExitVideo(event)}
          />
        </div>
        <Icon
          name="close"
          id="close-video"
          size="huge"
          color="grey"
        />
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ videos, upNext, autoplay }, { video }) => ({
  nextVideo: getNextAutoplayVideo(videos, video),
  videos,
  upNext: _.find(videos, { id: upNext }),
  autoplay,
  videoHash: _(videos).filter('started').groupBy('location').value()
});

const mapDispatchToProps = dispatch => ({
  updateProgress: (id, lastStoppedAt, completed, selected) =>
    dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),
  createProgress: id => dispatch(createVideoProgress(id))
});

export default connect(mapStateToProps, mapDispatchToProps)(YouTubePlayer);
