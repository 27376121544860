import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter, Route, Switch, Redirect} from 'react-router-dom';
import PropTypes from 'prop-types';
import { WorldMap } from './components';
import {me} from './store';

/**
 * COMPONENT
 */
class Routes extends Component {
  componentDidMount() {
    this.props.loadInitialData();
  }

  render() {
    const {isLoggedIn, isNotAdmin} = this.props;
    return (
      <Switch>
        <Route
          path="/map"
          render={props =>
            <WorldMap {...props} />
          }
        />
        <Route
          path="/add"
          render={props =>
            isNotAdmin ? <Redirect to="/map" /> : <WorldMap {...props} />
          }
        />
        {/* all other routes: fallback to /map if logged in, /login if not */}
        <Route
          render={() =>
            <Redirect to="/map" />
          }
        />
      </Switch>
    );
  }
}
/**
 * CONTAINER
 */
const mapState = state => {
  return {
    // Being 'logged in' for our purposes will be defined has having a state.user that has a truthy id.
    // Otherwise, state.user will be an empty object, and state.user.id will be falsey
    isLoggedIn: !!state.user.id,
    isNotAdmin: state.user && state.user.admin === false
  };
};

const mapDispatch = dispatch => {
  return {
    loadInitialData() {
      dispatch(me());
    }
  };
};

// The `withRouter` wrapper makes sure that updates are not blocked
// when the url changes
export default withRouter(connect(mapState, mapDispatch)(Routes));

/**
 * PROP TYPES
 */
Routes.propTypes = {
  loadInitialData: PropTypes.func.isRequired,
  isLoggedIn: PropTypes.bool.isRequired
};
