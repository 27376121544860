import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Popup, Icon, Form, Button, Dropdown } from 'semantic-ui-react';
import _ from 'lodash';

import { openedModal, closedModal } from '../store/openModal';
import { createVideo } from '../store/videos';
import { openedNewCityModal } from '../store/cityModalStage';
import { stringTimeToSeconds } from '../utils';

class AddVideoPopup extends Component {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.addVideo = this.addVideo.bind(this);
  }

  handleChange(e) {
    this.setState({[e.target.name]: e.target.value});
  }

  addVideo() {
    const { section, onSubmit, onClose } = this.props;
    const data = {
      ...this.state,
      length: stringTimeToSeconds(this.state.length),
      start: stringTimeToSeconds(this.state.start),
      end: stringTimeToSeconds(this.state.end),
      sectionId: section.id
    };
    return onSubmit(data)
      .then(() => onClose());
  }

  // TODO: react-dnd for drag and drop reordering
  render() {
    const { onOpen, onClose, locations, videos, isOpen, section, cityModalStage, openNewCityModal } = this.props;

    const triggerButton = (<div className="add-video-container">
        <Icon name="add" size="small" />
        <h2 id="add-video-title">Add Video</h2>
      </div>);

    const newCityButton = (<div key={-1} id="new-city-container">
      <Button type="button"
        onClick={ openNewCityModal }
        color="green"
        style={{
          width: '80%',
          maring: '5px auto'
        }}>
        New Location
      </Button>
    </div>);

    const locationDropdownOptions = _(locations)
      .orderBy(l => l.name.toLowerCase())
      .map(location => ({
        key: location.id,
        text: location.name,
        value: location.id
      }))
      .value();

    const videoOrderOptions = _(videos)
      .map(video => ({
        key: video.id,
        text: video.label,
        value: video.order + .5
      }))
      .orderBy('value')
      .value();

    return <Popup onOpen={ onOpen }
      onClose= { onClose }
      open={ isOpen }
      trigger={ triggerButton }
      on="click"
      position="right center"
      closeOnDocumentClick={ !cityModalStage }
      inverted >
      <Form id="add-video-form"
        onSubmit={ this.addVideo } >
        <h3>Add New Video to: </h3>
        <h4>"{section.label}"</h4>
        <Form.Field>
          <label>Video Title *</label>
          <input name="label"
            required
            placeholder="Video Title"
            onChange={this.handleChange} />
        </Form.Field>
        <Form.Group>
          <Form.Field width={9}>
            <label>Youtube Video ID *</label>
            <input name="urlCode"
              required
              placeholder="e.g., dQw4w9WgXcQ"
              onChange={this.handleChange} />
          </Form.Field>
          <Form.Field width={7}>
            <label>Video Length *</label>
            <input name="length"
              required
              placeholder="HH:MM:SS"
              onChange={this.handleChange} />
          </Form.Field>
        </Form.Group>
        <Form.Group widths="equal">
          <Form.Field>
            <label>Start Time: </label>
            <input name="start"
              placeholder="HH:MM:SS"
              onChange={this.handleChange} />
          </Form.Field>
          <Form.Field>
            <label>End Time: </label>
            <input name="end"
              placeholder="HH:MM:SS"
              onChange={this.handleChange} />
          </Form.Field>
        </Form.Group>
        <Form.Field>
          <label>Location *</label>
          <Dropdown placeholder="Choose or add a location..."
            search
            required
            selection
            fluid
            name="locationId"
            closeOnBlur={ !cityModalStage }
            options={ [newCityButton].concat(locationDropdownOptions) }
            onChange={ (e, { value }) => {
              const name = 'locationId';
              this.handleChange({ target: { name, value } });
            }}/>
        </Form.Field>
        <Form.Field id="last-field">
          <label>Order *</label>
          <Dropdown placeholder="Video comes after..."
            required
            selection
            fluid
            name="order"
            options={ [{ key: -1, text: 'Beginning of Section', value: -1 }].concat(videoOrderOptions) }
            onChange={ (e, { value }) => {
              const name = 'order';
              this.handleChange({ target: { name, value } });
            }} />
        </Form.Field>
        <Button style={{zIndex: '2'}} type="submit">
          Add Video
        </Button>
        <Button type="button"
          size="small"
          color="red"
          onClick={ onClose }
          style={{position: 'absolute', right: '0', top: '0'}}>
          Close
        </Button>
      </Form>
    </Popup>;
  }
}

const mapStateToProps = ({ locations, videos, openModal, cityModalStage }, { section }) => ({
  isOpen: openModal === `${section.id}-new`,
  locations,
  videos: _.filter(videos, { sectionId: section.id }),
  cityModalStage
});

const mapDispatchToProps = (dispatch, { section }) => ({
  onOpen: () => dispatch(openedModal(`${section.id}-new`)),
  onClose: () => dispatch(closedModal()),
  onSubmit: video => dispatch(createVideo(video)),
  openNewCityModal: () => dispatch(openedNewCityModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(AddVideoPopup);
