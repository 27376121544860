import React from 'react'
import GreyPinVideos from './pins/greyPinVideos'
import {connect} from 'react-redux'
const LocationsGrey = props => {
  const {videoHash, createProgress, updateProgress, locations} = props
  return (
    <React.Fragment>
      {locations.map(locationWatched => {
        if (locationWatched) {
          const {name, positionX, positionY} = locationWatched
          return (
            <GreyPinVideos
              key={name}
              y={positionY}
              x={positionX}
              id={name}
              videos={videoHash[name]}
              videosArr={props.videosArr}
              createProgress={createProgress}
              updateProgress={updateProgress}
              videoHash={videoHash}
            />
          )
        }
      })}
    </React.Fragment>
  )
}

const mapStateToProps = state => ({
  locations: state.locations
})

export default connect(mapStateToProps, null)(LocationsGrey)
