import React from 'react';
import _ from 'lodash';
import {Pin, GreyPin, ActivePin} from './pins';
export const Locations = props => {
  const { videoHash } = props;
  const {watched, clicked, notClicked, upNext} = props.organisePins();

  return (
    <React.Fragment>
      { upNext && <ActivePin locationId={upNext.locationId} /> }
      { watched.map(locationWatched => {
        if (locationWatched) {
          const {name, positionX, positionY} = locationWatched;
          return (
            <GreyPin
              key={name}
              y={positionY}
              x={positionX}
              id={name}
              videoHash={videoHash}
              location={upNext && upNext.location}
            />
          );
        }
      }) }
      { clicked.map(locationClicked => {
        if (locationClicked) {
          const {name, positionX, positionY} = locationClicked;
          return <Pin key={name} y={positionY} x={positionX} id={name} />;
        }
      }) }
      { notClicked.map(locationNotClicked => {
        if (locationNotClicked) {
          const {name, positionX, positionY} = locationNotClicked;
          return <Pin key={name} y={positionY} x={positionX} id={name} />;
        }
      }) }
    </React.Fragment>
  );
};
