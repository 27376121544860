import React from 'react';
import {connect} from 'react-redux';
import {Checkbox, Segment, Popup, Button} from 'semantic-ui-react';
import Cookies from 'js-cookie';

import VideoPlaylist from './video-playlist';
import YouTubePlayer from './youtube-player';
import {updateVideoProgress, createVideoProgress} from '../store/videos';
import { setUpNext } from '../store/upNext';
import { toggleAutoplay, setAutoplay } from '../store/autoplay';
import {logout} from '../store';
import {animation} from './animationFuncs';
import {Locations} from './locations';
import LocationsGrey from './locationsGrey';
import _ from 'lodash';
import moment from 'moment';
import history from '../history';

class WorldMap extends React.Component {
  state = {
    addPage: false
  }
  componentDidMount = () => {
    this.validateMounting();
    const autoplay = Cookies.get('autoplay') === 'true';
    this.props.setAutoplay(autoplay);
  }

  componentDidUpdate = prevProps => {
    if (!_.isEqual(prevProps, this.props)) {
      this.validateMounting();
    }
  }

  validateMounting = () => {
    if (this.props.match.path === '/add') {
      this.setState({addPage: true});
    } else if (this.props.match.path === '/map') {
      this.setState({addPage: false});
    }
  }

  organisePins = () => {
    const watchedLocations = [];
    const watched = [];
    const clickedLocations = [];
    const clicked = [];
    const notClickedLocation = [];
    const notClicked = [];
    ;
    this.props.videos.forEach(video => {
      if (video.completed && !watchedLocations.includes(video.location)) {
        watched.push({
          name: video.location,
          ...video
        });
        watchedLocations.push(video.location);
      } else if (
        video.started &&
        !_.isEqual(video, this.props.selectedVideo) &&
        !clickedLocations.includes(video.location) &&
        !video.completed
      ) {
        clicked.push({
          name: video.location,
          ...video
        });
        clickedLocations.push(video.location);
      } else if (!notClickedLocation.includes(video.location)) {
        notClicked.push({
          name: video.location,
          ...video
        });
        notClickedLocation.push(video.location);
      }
    });
    const upNextArr = this.props.videos.filter(video => video.upNext);
    const upNext = upNextArr[0];
    if (upNext) { this.props.setUpNext(upNext.id); }
    const allLocations = [];
    if (upNext) {
      allLocations.push(upNext.location);
    }

    watchedLocations.forEach(watch => {
      if (allLocations.includes(watch)) {
        watched.splice(watchedLocations.indexOf(watch), 1, null);
      }
      allLocations.push(watch);
    });

    clickedLocations.forEach(click => {
      if (allLocations.includes(click)) {
        clicked.splice(clickedLocations.indexOf(click), 1, null);
      }
      allLocations.push(click);
    });

    notClickedLocation.forEach(nClick => {
      if (allLocations.includes(nClick)) {
        notClicked.splice(notClickedLocation.indexOf(nClick), 1, null);
      }
      allLocations.push(nClick);
    });
    return {
      watched,
      clicked,
      notClicked,
      upNext
    };
  }

  resumePlaying = (video, videoHash) => {
    animation(
      video,
      this.props.createProgress,
      this.props.updateProgress,
      video,
      null,
      videoHash
    );
  }

  createVideoHashTable = videos => {
    const table = {};
    videos.forEach(video => {
      if (!table[video.location]) {
        table[video.location] = [];
      }
      if (video.started) table[video.location].push(video);
    });
    return table;
  }

  allCompleted = videos => {
    let result = true;
    for (let i = 0; i < videos.length; i++) {
      if (!videos[i].completed) {
        result = false;
        break;
      }
    }
    return result;
  }

  render() {
    const {upNext} = this.organisePins();
    let allCompleted;
    const { videos, autoplay, toggleAutoplay } = this.props;

    if (videos.length) {
      allCompleted = this.allCompleted(videos);
    }
    const videoHash = this.createVideoHashTable(videos);

    return (
      <div>
        <div id="small-screen-splash">
          <h1>Screen size not supported</h1>
        </div>
        <div id="title">
          <h1>
            Global State of Cyber <span id="year">{ moment().year() }</span>
          </h1>
          <hr id="line" />
          <p>Video footage of the top cyber threats worldwide</p>
        </div>
        <div id="logo-container">
          <img id="logo" src="/logo.png" />
        </div>
        <VideoPlaylist addPage={this.state.addPage}
          allCompleted={allCompleted} />
        {this.props.selectedVideo && (
          <YouTubePlayer video={this.props.selectedVideo} />
        )}
        <div className="map-container">
          <img id="world-map" src="/map.png" />
          {this.props.locations.length && this.state.addPage ? (
            <LocationsGrey
              createProgress={this.props.createProgress}
              updateProgress={this.props.updateProgress}
              videoHash={videoHash}
              videosArr={this.props.videos}
            />
          ) : (
            <Locations
              organisePins={this.organisePins}
              createProgress={this.props.createProgress}
              updateProgress={this.props.updateProgress}
              videoHash={videoHash}
            />
          )}

          <div id="bottom-right" className="corners" />
          <div id="top-right" className="corners" />
          <div id="top-left" className="corners" />
          <div id="bottom-left" className="corners" />
        </div>
        <div id="tr-buttons">
          {upNext &&
            !this.state.addPage && (
              <Button
                id="resume"
                color="green"
                type="button"
                onClick={() => this.resumePlaying(upNext, videoHash)}
              >
                Resume
              </Button>
            )}
          {!this.state.addPage && (
            <Segment id="autoplay">
              <Checkbox toggle
                label="AUTOPLAY"
                checked={ autoplay }
                onClick={ toggleAutoplay }/>
            </Segment>
          )}
          {this.props.isAdmin ? (
            !this.state.addPage ? (
              <Button id="admin-link"
                type="button"
                onClick={() => {
                  history.push('/add');
                }} >
                Admin View
              </Button>
            ) : (
              <Button id="map-link"
                type="button"
                onClick={() => history.push('/map')} >
                User View
              </Button>
            )
          ) : null}
          <Segment id="logout-container"
            onClick={this.props.logout}
            cursor="pointer">
            <Popup inverted
              trigger={
                <i cursor="pointer"
                  className="large sign out icon"
                  id="logout-button" />
              }
              content="Sign Out" />
          </Segment>
        </div>
      </div>
    );
  }
}

const mapStateToProps = ({ videos, locations, user, autoplay }) => ({
  selectedVideo: videos.filter(video => video.selected === true)[0],
  videos,
  locations,
  isAdmin: user.admin,
  autoplay
});

const mapDispatchToProps = dispatch => ({
  updateProgress: (id, lastStoppedAt, completed, selected) =>
    dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),
  createProgress: id => dispatch(createVideoProgress(id)),
  logout: () => dispatch(logout()),
  setUpNext: videoId => dispatch(setUpNext(videoId)),
  toggleAutoplay: () => dispatch(toggleAutoplay()),
  setAutoplay: autoplay => dispatch(setAutoplay(autoplay))
});

export default connect(mapStateToProps, mapDispatchToProps)(WorldMap);
