import React, {Component} from 'react';
import {Modal, Icon, Input, Button, Form} from 'semantic-ui-react';
import newCityPin from './newCityPin';
import {addNewLocation} from '../store/locations';
import { confirmNewCity, closedNewCityModal } from '../store/cityModalStage';
import {connect} from 'react-redux';

class NewCityModal extends Component {
  state = {
    cityName: '',
    newCityCoord: {}
  }

  handleChange = evt => {
    this.setState({
      [evt.target.name]: evt.target.value
    });
  }

  handleSubmit = () => {
    this.props.confirm();
    const addForm = document.getElementById('add-video-form');
    addForm.parentElement.style.cssText += ';display:none !important;';
    this.clickListener();
  }

  closeModal = () => {
    this.setState({
      cityName: '',
      newCityCoord: {}
    });
    this.props.close();
  }

  openModal = () => {
    this.setState({
      cityName: '',
      newCityCoord: {}
    });
  }

  clickListener = () => {
    const mapImg = document.getElementById('world-map');
    mapImg.style.cursor = 'crosshair';
    const onClick = evt => {
      mapImg.style.cursor = 'default';
      const newCityCoord = {
        positionX: evt.clientX / window.innerWidth * 100,
        positionY: evt.clientY / window.innerHeight * 100
      };

      this.setState({newCityCoord});

      const newCity = newCityPin({cityName: this.state.cityName, newCityCoord});
      const mapContainer = document.getElementsByClassName('map-container')[0];
      newCity.forEach(el => {
        let temp = document.createElement('div');
        temp.innerHTML = el;
        mapContainer.appendChild(temp.firstChild);
      });

      const circle = document.getElementById('new-circle');
      const ring = document.getElementById('new-ring');
      const label = document.getElementById('new-label');

      setTimeout(() => {
        circle.style.backgroundColor = 'red';
        ring.style.border = '3px solid red';
        label.style.color = 'white';
      }, 1);

      setTimeout(() => {
        this.setState({confirmation: true});
      }, 800);

      evt.target.removeEventListener(evt.type, onClick);
    };

    mapImg.addEventListener('click', onClick);
  }

  clickCancel = () => {
    const addForm = document.getElementById('add-video-form');
    addForm.parentElement.style.cssText += ';display:block !important;';
    this.setState({
      confirmation: false,
      cityName: '',
      newCityCoord: {}
    });
    const newThings = [...document.getElementsByClassName('new-city')];
    newThings.map(el => {
      el.remove();
    });
  }

  clickConfirm = () => {
    const addForm = document.getElementById('add-video-form');
    addForm.parentElement.style.cssText += ';display:block !important;';

    const {cityName, newCityCoord} = this.state;

    this.props.addNewLocation(
      cityName,
      newCityCoord.positionX,
      newCityCoord.positionY
    );

    const newThings = [...document.getElementsByClassName('new-city')];
    newThings.map(el => {
      el.remove();
    });

    this.setState({
      confirmation: false,
      cityName: '',
      newCityCoord: {}
    });
  }

  render() {
    const { cityModalStage } = this.props;

    return (
      <React.Fragment>
        <Modal
          size="tiny"
          onOpen={this.openModal}
          open={ cityModalStage === 1 } >
          <Modal.Header>Add New Location</Modal.Header>
          <Form id="new-city-form">
            <Form.Field>
              <label>New Location Name: </label>
              <Input
                required
                placeholder="e.g., New York City"
                name="cityName"
                onChange={this.handleChange}
              />
            </Form.Field>
          </Form>
          <Modal.Actions>
            <Button type="button" color="red" onClick={this.closeModal}>
              Close
            </Button>
            <Button
              onClick={this.handleSubmit}
              disabled={!this.state.cityName}
              type="submit"
              color="blue"
            >
              Submit
            </Button>
          </Modal.Actions>
        </Modal>
        {this.state.confirmation && (
          <div id="confirmation-container">
            <h2 style={{color: 'white', fontSize: '1.4em'}}>
              Confirm New Location:
            </h2>
            <div id="confirmation-buttons">
              <Button
                id="cancel-city"
                color="red"
                type="button"
                onClick={this.clickCancel}
              >
                Cancel
              </Button>
              <Button
                id="confirm-city"
                color="green"
                type="button"
                onClick={this.clickConfirm}
              >
                Confirm
              </Button>
            </div>
          </div>
        )}
      </React.Fragment>
    );
  }
}

const mapStateToProps = ({ cityModalStage }) => ({
  cityModalStage
});

const mapDispatchToProps = dispatch => ({
  addNewLocation: (name, x, y) => dispatch(addNewLocation(name, x, y)),
  confirm: () => dispatch(confirmNewCity()),
  close: () => dispatch(closedNewCityModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(NewCityModal);
