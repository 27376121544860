import Cookies from 'js-cookie';

/**
 * ACTION TYPES
 */
const TOGGLE_AUTOPLAY = 'TOGGLE_AUTOPLAY';
const SET_AUTOPLAY = 'SET_AUTOPLAY';

/**
 * INITIAL STATE
 */
const initialState = false;

/**
 * ACTION CREATORS
 */
export const toggleAutoplay = () => ({ type: TOGGLE_AUTOPLAY });
export const setAutoplay = autoplay => ({ type: SET_AUTOPLAY, autoplay });

/**
 * REDUCER
 */
export default function(state = initialState, action) {
  switch (action.type) {
    case TOGGLE_AUTOPLAY:
      Cookies.set('autoplay', !state);
      return !state;
    case SET_AUTOPLAY:
      return action.autoplay;
    default:
      return state;
  }
}
