import React from 'react'
import {Popup, Icon} from 'semantic-ui-react'
import {animation} from '../animationFuncs'

const GreyPinVideos = props => {
  const videos = props.videosArr.filter(video => video.location === props.id)
  return (
    <React.Fragment>
      <div
        className="ringring"
        id={`ringing${props.id}`}
        style={{
          top: `calc(${props.y}vh - 12.5px)`,
          left: `calc(${props.x}vw - 12.5px)`
        }}
      />

      <Popup
        header={props.id}
        inverted
        hoverable
        trigger={
          <div
            className="circle"
            id={`circle${props.id}`}
            style={{
              backgroundColor: 'grey',
              top: `calc(${props.y}vh - 7.5px)`,
              left: `calc(${props.x}vw - 7.5px)`
            }}
          />
        }
        content={videos.map(video => (
          <div id={`${video.id}`} className="video-list-item" key={video.id}>
            <Icon name="map marker alternate" color="blue" />
            {video.label}
          </div>
        ))}
      />
      <h3
        className="location"
        id={`location${props.id}`}
        style={{
          fontFamily: 'Helvetica, sans serif',
          top: `calc(${props.y}vh - 17.5px)`,
          left: `calc(${props.x}vw - 3.5px)`,
          zIndex: '-1'
        }}
      >
        {props.id}
      </h3>
    </React.Fragment>
  )
}

export default GreyPinVideos
