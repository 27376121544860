import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Accordion,
  Icon
} from 'semantic-ui-react';

import { createVideoProgress, updateVideoProgress } from '../store/videos';

import AddVideoPopup from './AddVideoPopup';
import EditVideoPopup from './EditVideoPopup';
import VideoPopup from './videoPopup';
import EditSectionPopup from './EditSectionPopup';


class SingleSectionAccordion extends Component {
  render () {
    const {
      section,
      videos,
      isActiveSection,
      setActiveSection,
      isAddPage
    } = this.props;

    const completed = videos.every(v => v.completed);

    return ((!isAddPage && !videos.length)
      ? null
      : <div key={section.id}>
        <Accordion.Title active={ isActiveSection }
          index={section.id}
          onClick={ () => setActiveSection(section.id) } >
          { !isAddPage && completed ? (
            <Icon name="check" color="green" id="section-check" />
            ) : (
            <Icon name="dropdown" />
          )}
          {section.label}
        </Accordion.Title>
        <Accordion.Content className="section-video-list"
          active={ isActiveSection } >
          { isAddPage && <EditSectionPopup section={ section} /> }
          { isAddPage && <AddVideoPopup section={ section } /> }
          { _(videos)
            .filter({ sectionId: section.id })
            .orderBy('order')
            .map((video, idx) =>
              isAddPage ? (
                <EditVideoPopup section={ section }
                  video={ video }
                  mode="edit" />
              ) : (
                <VideoPopup key={video.id}
                  video={video}
                  idx={idx} />
              )
            ).value() }
        </Accordion.Content>
      </div>);
  }
}


const mapStateToProps = ({ videos }, { section }) => ({
  videos: _.filter(videos, { sectionId: section.id })
});

const mapDispatchToProps = dispatch => ({
  // createProgress: id => dispatch(createVideoProgress(id)),
  // updateProgress: (id, lastStoppedAt, completed, selected) =>
    // dispatch(updateVideoProgress(id, lastStoppedAt, completed, selected)),

});

export default connect(mapStateToProps, mapDispatchToProps)(SingleSectionAccordion);
