import {createStore, combineReducers, applyMiddleware} from 'redux';
// import createLogger from 'redux-logger';
import thunkMiddleware from 'redux-thunk';
import {composeWithDevTools} from 'redux-devtools-extension';
import user from './user';
import videos from './videos';
import autoplay from './autoplay';
import sections from './sections';
import locations from './locations';
import openModal from './openModal';
import cityModalStage from './cityModalStage';
import upNext from './upNext';

const reducer = combineReducers({
  user,
  videos,
  autoplay,
  sections,
  locations,
  openModal,
  cityModalStage,
  upNext
});

const middleware = composeWithDevTools(
  applyMiddleware(thunkMiddleware/*, createLogger({collapsed: true})*/)
);
const store = createStore(reducer, middleware);

export default store;
export * from './user';
