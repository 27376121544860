import React, {Component} from 'react';
import {connect} from 'react-redux';
import _ from 'lodash';
import {
  Accordion,
  Icon,
  Ref,
  Form
} from 'semantic-ui-react';
import {getSections, addNewSection} from '../store/sections';
import {
  getVideos,
  addNewVideo,
  updateVideoOrder
} from '../store/videos';
import {getLocations} from '../store/locations';
// import {stringTimeToSeconds, getVideosAfterUpdate} from '../utils';
import NewCityModal from './newCityModal';
import SingleSectionAccordion from './SingleSectionAccordion';

class VideoSelection extends Component {
  constructor() {
    super();
    this.state = {
      activeIndex: -1,
      activeSection: null,
      showAddSection: false,
      newTitle: '',
      openModal: false,
      accordionRef: null
    };
  }

  setActiveSection = sectionId => {
    if (this.state.activeSection === sectionId) {
      this.setState({ activeSection: null });
    } else {
      this.setState({ activeSection: sectionId });
    }
  }

  handleRef = accordionRef => {
    this.setState({accordionRef});
  }

  componentDidMount() {
    this.props.loadSections();
    // this.props.loadVideos()
    this.props.loadLocations();
  }

  componentDidUpdate() {
    // placed in ...DidUpdate because ref is still `null` on mount
    if (this.state.accordionRef)
      this.props.setParentHeight(this.state.accordionRef.clientHeight);
  }

  handleClick = (e, titleProps) => {
    const {index} = titleProps;
    const {activeIndex} = this.state;
    const newIndex = activeIndex === index ? -1 : index;
    this.setState({activeIndex: newIndex});
  }

  handleChange = e => {
    this.setState({[e.target.name]: e.target.value});
  }
  handleDropDownChange = (e, dropdown) => {
    this.setState({
      newVideoLocationId: dropdown.value
    });
  }

  handleAddSection = () => {
    this.props.addSection(this.state.newTitle, this.props.sections.length + 1);
    this.setState({showAddSection: false, newTitle: ''});
  }

  // handleAddVideo = (sectionId, lastSectionVideoOrder) => {
  //   let startTime = null;
  //   let endTime = null;
  //   if (this.state.newVideoStart || this.state.newVideoEnd) {
  //     startTime = stringTimeToSeconds(this.state.newVideoStart);
  //     endTime = stringTimeToSeconds(this.state.newVideoEnd);
  //   }
  //   let lengthToSeconds = stringTimeToSeconds(this.state.newVideoLength);
  //   const videoData = {
  //     urlCode: this.state.newVideoID,
  //     label: this.state.newVideoTitle,
  //     length: lengthToSeconds,
  //     sectionId: sectionId,
  //     start: startTime,
  //     end: endTime,
  //     order: lastSectionVideoOrder + 1,
  //     locationId: this.state.newVideoLocationId
  //   };

  //   this.props.addVideo(videoData);
  //   this.props.loadVideos();

  //   this.handleClose();
  // }

  // handleClose = (name, id) => {
  //   this.setState({
  //     newVideoTitle: '',
  //     newVideoID: '',
  //     newVideoLength: '',
  //     newVideoStart: null,
  //     newVideoEnd: null,
  //     newVideoLocationId: null
  //   });
  // }

  handleModalClose = () => {
    this.setState({openModal: false});
  }

  sectionOrderArray = section => {
    const result = [];
    const beginningIsIn = {};

    if (section.videos) {
      section.videos.sort((a, b) => a.order - b.order).forEach(video => {
        if (!beginningIsIn[section.id]) {
          result.push({
            text: 'Beginning of Section',
            key: 'beg' + section.id,
            value: video.order - 1
          });
          beginningIsIn[section.id] = true;
        }

        result.push({
          key: video.id,
          value: video.order,
          text: video.label
        });
      });
    }
    return result;
  }

  render() {
    const { sections, addPage } = this.props;
    const { activeSection} = this.state;

    return (
      <Ref innerRef={this.handleRef}>
        <Accordion styled id="video-selection">
          <NewCityModal/>
          { addPage && (
            <Accordion.Title active>
              {this.state.showAddSection ? (
                <Form>
                  <div className="add-section-container">
                    <input placeholder="Section Title"
                      required
                      name="newTitle"
                      onChange={this.handleChange} />
                    <div id="icon-container">
                      <Icon name="add"
                        color="green"
                        onClick={this.handleAddSection} />
                      <Icon name="close"
                        color="red"
                        onClick={() => this.setState({showAddSection: false, newTitle: ''})} />
                    </div>
                  </div>
                </Form>
              ) : (
                <div className="add-section-container"
                  onClick={() => this.setState({showAddSection: true})} >
                  <Icon name="add" />
                  <h2 id="add-section-title">Add Section</h2>
                </div>
              )}
            </Accordion.Title>
          )}

          { _.orderBy(sections, 'order').map(section => {
            // const sectionVideos = _.filter(videos, { sectionId: section.id });
            // const lastSectionVideos = videos
            //   .filter(video => {
            //     const lastSectionArray = sections.filter(
            //       innerSection => innerSection.order === section.order - 1
            //     );
            //     const lastSectionId = lastSectionArray[0]
            //       ? lastSectionArray[0].id
            //       : null;
            //     return video.sectionId === lastSectionId;
            //   })
            //   .sort((a, b) => a.order - b.order);
            // const lastVideoInSection =
            //   lastSectionVideos[lastSectionVideos.length - 1];
            // const lastVideo = sectionVideos[sectionVideos.length - 1];
            // const lastSectionVideoOrder = lastVideo && lastVideo.order;
            // const completed = sectionVideos.every(video => video.completed);
            // let nextOrderNumber;
            // if (!sectionVideos.length) {
            //   nextOrderNumber = lastVideoInSection && lastVideoInSection.order;
            // } else {
            //   nextOrderNumber = lastSectionVideoOrder;
            // }

            // const sectionVideosArray = this.sectionOrderArray(section);

            return (
              <SingleSectionAccordion key={ section.id }
                section={ section }
                isActiveSection={ activeSection === section.id }
                setActiveSection={ this.setActiveSection }
                isAddPage={ addPage }/>
            );
          })}
        </Accordion>
      </Ref>
    );
  }
}

const mapStateToProps = ({sections, videos, locations}) => {
  const locationOptions = locations
    .map(location => ({
      key: location.id,
      text: location.name,
      value: location.id
    }))
    .sort();
  return {sections, videos, locations: locationOptions};
};

const mapDispatchToProps = dispatch => ({
  loadLocations: () => dispatch(getLocations()),
  loadSections: () => dispatch(getSections()),
  // loadVideos: () => dispatch(getVideos()),
  addSection: (label, order) => dispatch(addNewSection(label, order)),
  addVideo: videoData => dispatch(addNewVideo(videoData)),
  updateVideo: (order, id) => dispatch(updateVideoOrder(order, id))
});

export default connect(mapStateToProps, mapDispatchToProps)(VideoSelection);
