import axios from 'axios'
import history from '../history'
import {getVideos} from './videos'

/**
 * ACTION TYPES
 */
const RECEIVE_USER = 'RECEIVE_USER'
const REMOVE_USER = 'REMOVE_USER'
const GET_VIDEO_TIMES = 'GET_VIDEO_TIMES'

/**
 * INITIAL STATE
 */
const defaultUser = {}

/**
 * ACTION CREATORS
 */
const getUser = user => ({type: RECEIVE_USER, user})
const removeUser = () => ({type: REMOVE_USER})
const gotVideoTimes = videoTimes => ({
  type: GET_VIDEO_TIMES,
  videoTimes
})

/**
 * THUNK CREATORS
 */
export const getVideoTotalTimes = () => async dispatch => {
  try {
    const res = await axios.get(`/api/videos/runningTimes`)
    dispatch(gotVideoTimes(res.data))
  } catch (error) {
    console.error(error)
  }
}
export const me = () => async dispatch => {
  try {
    const res = await axios.get('/auth/me')
    dispatch(getUser(res.data))
    await dispatch(getVideos())
    await dispatch(getVideoTotalTimes())
  } catch (err) {
    console.error(err)
    const dev = window.location.origin.includes('localhost');
    window.location = `${dev ? 'http://localhost:7575' : 'https://welcome.fullstackacademy.com' }#?login&callbackUrl=${window.location.origin}`;
    // return dispatch(getUser({error: err}))
  }
}

export const auth = (email, password, method) => async dispatch => {
  let res
  try {
    res = await axios.post(`/auth/${method}`, {email, password})
  } catch (authError) {
    return dispatch(getUser({error: authError}))
  }

  try {
    dispatch(getUser(res.data))
    await dispatch(getVideoTotalTimes())
    history.push('/home')
  } catch (dispatchOrHistoryErr) {
    console.error(dispatchOrHistoryErr)
  }
}

export const logout = () => async dispatch => {
  try {
    await axios.post('/auth/logout')
    dispatch(removeUser())
    // history.push('/login')

    const dev = window.location.origin.includes('localhost');
    window.location = `${dev ? 'http://localhost:7575' : 'https://welcome.fullstackacademy.com' }#?login&callbackUrl=${window.location.origin}`;
  } catch (err) {
    console.error(err)
  }
}

/**
 * REDUCER
 */
export default function(state = defaultUser, action) {
  switch (action.type) {
    case RECEIVE_USER:
      return action.user
    case REMOVE_USER:
      return defaultUser
    case GET_VIDEO_TIMES:
      return {...state, ...action.videoTimes}
    default:
      return state
  }
}
