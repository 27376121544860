import React, { Component } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import {
  Icon,
  Popup,
  Form,
  Button,
  Dropdown
} from 'semantic-ui-react';
import { updateSection } from '../store/sections';
import { openedModal, closedModal } from '../store/openModal';

class EditSectionPopup extends Component {
  constructor(props) {
    super(props);
    const { section } = props;
    this.state = {
      ...section
    };

    this.onSubmit = this.onSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  onSubmit() {
    const { onSubmit, onClose } = this.props;
    return onSubmit(this.state)
      .then(() => onClose());
  }

  handleChange(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  render() {
    const { section, sections, isOpen, onOpen, onClose, onDelete } = this.props;
    const triggerButton = (<div className="add-video-container">
      <Icon name="edit" size="small" />
      <h2 id="add-video-title">Edit Section</h2>
    </div>);

    const sectionOrderOptions = _(sections)
      .reject({ id: section.id })
      .map(s => ({
        key: s.id,
        text: s.label,
        value: s.order + .5
      }))
      .orderBy('value')
      .value();

    return (<Popup onOpen={ onOpen }
      open={ isOpen }
      trigger={ triggerButton }
      on="click"
      position="right center"
      inverted >
      <Form id="add-video-form"
        style={{ height: 'auto' }}
        onSubmit={ this.onSubmit }>
        <h3>Edit Section:</h3>
        <Form.Field>
          <label>Section Title *</label>
          <input name="label"
            defaultValue={ this.state.label }
            required
            placeholder="Section Title"
            onChange={ this.handleChange } />
        </Form.Field>
        <Form.Field id="last-field">
          <label>Order </label>
          <Dropdown placeholder="Section comes after..."
            selection
            fluid
            name="order"
            options={ [{ key: -1, text: 'Beginning of Playlist', value: -1 }].concat(sectionOrderOptions) }
            onChange={ (e, { value }) => {
              const name = 'order';
              this.handleChange({ target: { name, value } });
            }} />
        </Form.Field>
        <Button style={{zIndex: '2'}} color="green" type="submit">
          Update Section
        </Button>
        <div id="edit-video-delete-close"
            style={{position: 'absolute', right: '0', top: '0'}}>
            <Button
              type="button"
              size="small"
              color="red"
              onClick={ onDelete } >
              Delete
            </Button>
            <Button
              type="button"
              size="small"
              color="blue"
              onClick={ onClose }>
              Close
            </Button>
          </div>
      </Form>
    </Popup>);
  }
}


const mapStateToProps = ({ sections, openModal }, { section }) => ({
  isOpen: openModal === `${section.id}-edit`,
  sections
});
const mapDispatchToProps = (dispatch, { section }) => ({
  onOpen: () => dispatch(openedModal(`${section.id}-edit`)),
  onClose: () => dispatch(closedModal()),
  onSubmit: data => dispatch(updateSection(data)),
  onDelete: () => {
    if (confirm('Are you sure you watn to delete this section?')) {

    }
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(EditSectionPopup);
