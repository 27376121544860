import React, { Component } from 'react';
import {
  Icon,
  Popup,
  Input,
  Button,
  Form,
  Dropdown
} from 'semantic-ui-react';
import { connect } from 'react-redux';
import {convertSecondsToHHMMSS, stringTimeToSeconds} from '../utils';
import { updateVideo, updateVideoOrder, deleteVideo } from '../store/videos';
import { openedModal, closedModal } from '../store/openModal';
import { openedNewCityModal } from '../store/cityModalStage';
import _ from 'lodash';

class EditVideoPopup extends Component {
  constructor(props) {
    super(props);
    const { video } = props;
    this.state = {
      label: video.label,
      urlCode: video.urlCode,
      length: convertSecondsToHHMMSS(video.length),
      start: convertSecondsToHHMMSS(video.start) || '',
      end: convertSecondsToHHMMSS(video.end) || '',
      locationId: video.locationId,
      id: props.video.id,
      sectionId: video.sectionId,
      order: video.order
    };
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleChange = event => {
    console.log(event.target)
    this.setState({[event.target.name]: event.target.value});
  }

  onSubmit() {
    const { onSubmit, onClose } = this.props;
    const data = {
      ...this.state,
      length: stringTimeToSeconds(this.state.length),
      start: stringTimeToSeconds(this.state.start),
      end: stringTimeToSeconds(this.state.end)
    };
    return onSubmit(data)
      .then(() => onClose());
  }

  render() {
    const {
      section,
      video,
      sectionVideosArray,
      newVideoLocation,
      locations,
      handleOpen,
      handleClose,
      sections,
      isOpen,
      onOpen,
      onClose,
      onDelete,
      openNewCityModal,
      cityModalStage,
      videos
    } = this.props;

    const triggerButton = (<div id={`${video.id}`}
      className="video-list-item"
      key={video.id}>
        <Icon name="edit" size="small" />
        {video.label}
      </div>);

    const newCityButton = (<div key={-1} id="new-city-container">
      <Button type="button"
        onClick={ openNewCityModal }
        color="green"
        style={{
          width: '80%',
          maring: '5px auto'
        }}>
        New Location
      </Button>
      </div>);

    const locationDropdownOptions = _(locations)
      .orderBy(l => l.name.toLowerCase())
      .map(location => ({
        key: location.id,
        text: location.name,
        value: location.id
      }))
      .value();

    const videoOrderOptions = _(videos)
      .reject({ id: video.id })
      .map(video => ({
        key: video.id,
        text: video.label,
        value: video.order + .5,
        section: video.sectionId
      }))
      .orderBy('value')
      .groupBy('section')
      .value();

    const sectionOptions = _(sections)
      .orderBy('order')
      .map(section => ({
        key: section.id,
        text: section.label,
        value: section.id
      }))
      .value();

    return (
      <Popup
        onOpen={ onOpen }
        open={ isOpen }
        trigger={ triggerButton }
        on="click"
        position="right center"
        closeOnDocumentClick={ !cityModalStage }
        inverted>
        <Form id="add-video-form" onSubmit={ this.onSubmit }>
          <h3>Edit Video: </h3>
          <h4>"{video.label}"</h4>
          <Form.Field>
            <label>Video Title *</label>
            <input name="label"
              defaultValue={ this.state.label }
              required
              placeholder="Video Title"
              onChange={this.handleChange} />
          </Form.Field>
          <Form.Group>
            <Form.Field width={9}>
              <label>Youtube Video ID *</label>
              <input name="urlCode"
                defaultValue={ this.state.urlCode }
                required
                placeholder="e.g., dQw4w9WgXcQ"
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field width={7}>
              <label>Video Length *</label>
              <input name="length"
                defaultValue={ this.state.length }
                required
                placeholder="HH:MM:SS"
                onChange={this.handleChange} />
            </Form.Field>
          </Form.Group>
          <Form.Group widths="equal">
            <Form.Field>
              <label>Start Time: </label>
              <input name="start"
                defaultValue={ this.state.start }
                placeholder="HH:MM:SS"
                onChange={this.handleChange} />
            </Form.Field>
            <Form.Field>
              <label>End Time: </label>
              <input name="end"
                defaultValue={ this.state.end }
                placeholder="HH:MM:SS"
                onChange={this.handleChange} />
            </Form.Field>
          </Form.Group>
          <Form.Field>
            <label>Location *</label>
            <Dropdown placeholder="Choose or add a location..."
              search
              required
              defaultValue={ this.state.locationId }
              selection
              fluid
              name="locationId"
              closeOnBlur={ !cityModalStage }
              options={ [newCityButton].concat(locationDropdownOptions) }
              onChange={ (e, { value }) => {
                const name = 'locationId';
                this.handleChange({ target: { name, value } });
              }}/>
          </Form.Field>
          <Form.Field id="last-field">
            <label>Section (only select to modify order)</label>
            <Dropdown
              placeholder="Select a section..."
              selection
              defaultValue={ this.state.sectionId }
              options={ sectionOptions }
              fluid
              className="link item"
              name="sectionId"
              onChange={ (e, { value }) => {
                const name = 'sectionId';
                this.handleChange({ target: { name, value } });
                this.handleChange({ target: { name: 'order', value: -1 } });
              }}
            />
          </Form.Field>
          <Form.Field id="last-field">
          <label>Order</label>
            <Dropdown placeholder="Video comes after..."
              selection
              fluid
              name="order"
              options={ [{ key: -1, text: 'Beginning of Section', value: -1 }].concat(videoOrderOptions[this.state.sectionId] || []) }
              onChange={ (e, { value }) => {
                const name = 'order';
                this.handleChange({ target: { name, value } });
              }} />
          </Form.Field>
          <Button style={{zIndex: '2'}} color="green" type="submit">
            Update Video
          </Button>

          <div id="edit-video-delete-close"
            style={{position: 'absolute', right: '0', top: '0'}}>
            <Button
              type="button"
              size="small"
              color="red"
              onClick={ onDelete } >
              Delete
            </Button>
            <Button
              type="button"
              size="small"
              color="blue"
              onClick={ onClose }>
              Close
            </Button>
          </div>
        </Form>
      </Popup>
    );
  }
}

const mapStateToProps = ({ sections, videos, locations, openModal, cityModalStage }, { section, video }) => ({
  isOpen: openModal === `${section.id}-${video.id}`,
  sections: sections,
  videos: videos,
  locations,
  cityModalStage
});

const mapDispatchToProps = (dispatch, { section, video }) => ({
  onOpen: () => dispatch(openedModal(`${section.id}-${video.id}`)),
  onClose: () => dispatch(closedModal()),
  onSubmit: data => dispatch(updateVideo(data)),
  onDelete: () => {
    if (confirm('Are you sure you want to delete this video?')) {
      return dispatch(deleteVideo(video.id));
    }
  },
  openNewCityModal: () => dispatch(openedNewCityModal())
});

export default connect(mapStateToProps, mapDispatchToProps)(EditVideoPopup);
