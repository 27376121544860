import axios from 'axios'

/**
 * ACTION TYPES
 */

const GOT_LOCATIONS = 'GOT_LOCATIONS'
const ADD_LOCATION = 'ADD_LOCATION'

/**
 * INITIAL STATE
 */

const initialState = []

/**
 * ACTION CREATORS
 */

export const gotLocations = locations => ({type: GOT_LOCATIONS, locations})
export const addLocation = location => ({type: ADD_LOCATION, location})

/**
 * THUNK CREATORS
 */

export const getLocations = () => async dispatch => {
  try {
    const res = await axios.get('/api/locations')
    dispatch(gotLocations(res.data))
  } catch (error) {
    console.error(error)
  }
}

export const addNewLocation = (
  name,
  positionX,
  positionY
) => async dispatch => {
  try {
    const res = await axios.post('/api/locations', {name, positionX, positionY})
    dispatch(addLocation(res.data))
  } catch (error) {
    console.error(error)
  }
}

export default function(state = initialState, action) {
  switch (action.type) {
    case GOT_LOCATIONS:
      return action.locations;
    case ADD_LOCATION:
      return [...state, action.location];
    default:
      return state;
  }
}
