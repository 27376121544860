import {getUpNext} from '../../server/utils/queryResults'

export function getVideoById(state, actionId) {
  return state.filter(video => video.id === actionId)[0]
}

export function getVideosAfterUpdate(state, updatedVideo) {
  return [...state.filter(video => video.id !== updatedVideo.id), updatedVideo]
}

export function getVideosWithUpNext(videos) {
  const upNext = getUpNext(videos)
  let updatedVideos
  if (upNext) {
    updatedVideos = [
      ...videos
        .filter(video => video.id !== upNext.id)
        .map(video => ({...video, upNext: false})),
      {...upNext, upNext: true}
    ]
  } else {
    updatedVideos = videos.map(video => ({...video, upNext: false}))
  }
  return updatedVideos.sort((a, b) => a.id - b.id)
}

export function updateLastStoppedAt(currentStoppedAt, video) {
  const previousStoppedAt = video.lastStoppedAt
  if (video.start && currentStoppedAt < video.start) return previousStoppedAt
  if (video.end && currentStoppedAt > video.end) return previousStoppedAt
  return currentStoppedAt
}

export function stringTimeToSeconds(str) {
  if (!str || str === 'null') {
    return null
  }
  let numLength
  let strArr = str.split(':').map(numStr => Number(numStr))
  if (strArr.length === 3) {
    numLength = 60 * 60 * strArr[0] + 60 * strArr[1] + strArr[2]
  } else {
    numLength = 60 * strArr[0] + strArr[1]
  }
  return numLength
}

/* AUTOPLAY UTILS */

// Get all videos that are not the current video and have not yet been finished.
const getAutoplayCandidates = (allVideos, currentVideo) =>
  allVideos.filter(
    video => video.id !== currentVideo.id && video.completed === false
  )

// 'distance' is the number of spots a given video is in the order from the
// current video. So, if the current video is #6 in the order, and a given video
// is #12, then the distance is 6. We will attach this computed `distance`
// property to only the unfinished videos on state and use it to determine the
// next video chosen by autoplay.
const addDistanceToVideos = (videos, currentVideo, allVideosLength) => {
  return videos.map(unfinishedVideo => {
    const distance =
      unfinishedVideo.order < currentVideo.order
        ? allVideosLength - currentVideo.order + unfinishedVideo.order
        : unfinishedVideo.order - currentVideo.order
    return {...unfinishedVideo, distance}
  })
}

const minDistance = videosWithDistance =>
  Math.min(...videosWithDistance.map(video => video.distance))

const getVideoWithLeastDistance = videosWithDistance => {
  const minAutoplayDistance = minDistance(videosWithDistance)
  return videosWithDistance.length > 0
    ? videosWithDistance.filter(
        video => video.distance === minAutoplayDistance
      )[0]
    : null
}

export function getNextAutoplayVideo(allVideos, currentVideo) {
  const autoplayCandidates = getAutoplayCandidates(allVideos, currentVideo)
  const autoplayCandidatesWDistance = addDistanceToVideos(
    autoplayCandidates,
    currentVideo,
    allVideos.length
  )
  return getVideoWithLeastDistance(autoplayCandidatesWDistance)
}

const addLeadingZeroesToTimes = (...times) =>
  times.map(time => `00${time}`.slice(-2))

export function convertSecondsToHHMMSS(totalSeconds) {
  if (!totalSeconds) return null
  const hours = Math.floor(+totalSeconds / 3600)
  const secondsUnderAnHour = +totalSeconds % 3600
  const minutes = Math.floor(secondsUnderAnHour / 60)
  const seconds = +totalSeconds % 60
  const [sHours, sMinutes, sSeconds] = addLeadingZeroesToTimes(
    hours,
    minutes,
    seconds
  )
  return `${sHours}:${sMinutes}:${sSeconds}`
}
